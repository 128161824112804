export const educationData = [
    {
        id: 1,
        institution: 'Indian Institute of Technology Bubaneshwar (cgpa : 9.32)',
        course: 'Mtech (CSE)',
        startYear: '2021',
        endYear: '2023'
    },
    {
        id: 2,
        institution: 'SRM University Chennai (cgpa : 8.91)',
        course: 'BTech (CSE)',
        startYear: '2015',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'Sri Chaitanya (94%)',
        course: 'Higher Secondary Education (11th - 12th)',
        startYear: '2013',
        endYear: '2015'
    },
    {
        id: 4,
        institution: 'KV DRDO (cgpa : 9.6)',
        course: 'Secondary Education (10th)',
        startYear: '2012',
        endYear: '2013'
    }
]