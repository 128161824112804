export const achievementData = {
    bio : "",
    achievements : [
        {
            id : 1,
            title : 'AWS Certification (AWS Solutions Architect Associate)',
            details : 'Cloud expertise in cloud migration ,integration with cloud and deploying complex models',
            date : 'June 2019',
            field : 'Cloud',
            image : 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id : 2,
            title : 'GATE',
            details : 'Qualified GATE twice with the best All India Rank 692',
            date : '2021, 2022',
            field : 'GATE',
            image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/