import resume from '../assets/pdf/resume.pdf'
import suman from '../assets/suman.jpeg'

export const headerData = {
    name: 'NVSSK Suman',
    title: "Student and a Software Developer",
    desciption:"I am a passionate guy with few years of induatry experience behind my belt. Always eager and enthusiastic to learn.",
    image: suman,
    resumePdf: resume
}
