export const experienceData = [
    {
        id: 1,
        company: 'IIT Bhubaneshwar',
        jobtitle: 'Teaching Assistant',
        startYear: 'Aug - 2021',
        endYear: 'May - 2023'
    },
    {
        id: 2,
        company: 'Hashedin by Deloitte',
        jobtitle: 'Software Development Engineer - 1',
        startYear: 'May - 2019',
        endYear: 'May - 2020'
    },
    {
        id: 3,
        company: 'Hashedin by Deloitte',
        jobtitle: 'Software Development Intern',
        startYear: 'Feb - 2019',
        endYear: 'May - 2019'
    }
]