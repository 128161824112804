import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Formal verification of DRAM Controller',
        projectDesc: 'Model the DRAM controller in uppaal and formally verify its saftey and liveness properties',
        tags: ['Formal verifivation', 'Model checking', 'DRAM', 'Uppaal'],
        code: '',
        demo: '',
        image: three
    },
    {
        id: 2,
        projectName: 'Web-app to perform image steganography',
        projectDesc: 'Developed an algorithm based on LSB encryption for hiding text information inside the image.',
        tags: ['React', 'CSS', 'Django', 'Docker', 'Python', 'open-cv'],
        code: '',
        demo: '',
        image: one
    },
    {
        id: 3,
        projectName: 'Digital Watermarking',
        projectDesc: 'Developed an algorithm based on DCT to embed the watermark inside the source image.',
        tags: ['Python', 'open-cv'],
        code: '',
        demo: '',
        image: two
    },
    {
        id: 4,
        projectName: 'Personalised cancer diagnosis',
        projectDesc: 'Predict the effect of Genetic Variants to enable Personalized Medicine',
        tags: ['Python', 'Naive-Bayes', 'SVM', 'Logistic Regression'],
        code: '',
        demo: '',
        image: four
    },
    {
        id: 5,
        projectName: 'Predict heart patients',
        projectDesc: 'Predict if a person is a heart patient based on his medical records',
        tags: ['Python', 'PCA', 'Logistic Regression', 'Neural Networks'],
        code: '',
        demo: '',
        image: five
    },
    {
        id: 6,
        projectName: 'Copy move forgery detection',
        projectDesc: 'Copy move forgery detection using SIFT',
        tags: ['Python', 'SIFT', 'Forgery Detection'],
        code: '',
        demo: '',
        image: seven
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/